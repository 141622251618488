import { Dictionary } from '@reduxjs/toolkit';

import {
  BrokerAccount,
  Currency,
  ExchangeItem,
  ExchangeModel,
  FeedGateway,
  Section,
} from '~/types/models';
import { EmptyResponse } from '~/types/shared';

import { IBrokerProvider } from '../pages/Brokers/types';
import { ExecutionSchemaItem } from '../pages/ExecutionSchemes/types';
import {
  IFeedChangeResponse,
  IFeedPayload,
  IFeedProvider,
} from '../pages/Feeds/types';
import { ILocalizationFormValues } from '../pages/Localizations/types';
import { IReportingFormValues } from '../pages/Reporting/types';
import {
  SchedulesResponse,
  SchedulesResponseItem,
} from '../pages/Schedules/types';
import { ISymbolTypeFormValues } from '../pages/SymbolTypes/types';
import { IFormValues } from '../pages/Themes/types';

import { apiService } from './api/api.service';
import { getSymbolDBBaseUrl } from './api/helpers';
import { Endpoint } from './constants';
import { getSessionIdFromCookie } from './cookies/cookies.service';
import { CurrencyHoliday, LegalEntitiesResponse } from './types';

const symbolDBAPI = apiService(getSymbolDBBaseUrl());

export const symbolDBService = () => {
  const createLocalization = async (values: ILocalizationFormValues) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.Localizations}/`,
        data: [values],
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const createTheme = async (values: IFormValues) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.Themes}/`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const deleteLocalization = async (id: string) => {
    try {
      const result = await symbolDBAPI.delete({
        endPoint: `${Endpoint.Localizations}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const deleteTheme = async (id: string) => {
    try {
      const result = await symbolDBAPI.delete({
        endPoint: `${Endpoint.Themes}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getLocalizations = async () => {
    try {
      const result = await symbolDBAPI.get({
        endPoint: Endpoint.Localizations,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getThemes = async () => {
    try {
      const result = await symbolDBAPI.get({
        endPoint: Endpoint.Themes,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getSingleLocalization = async (id?: string) => {
    try {
      const result = await symbolDBAPI.get({
        endPoint: `${Endpoint.Localizations}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getSingleTheme = async (id?: string) => {
    try {
      const result = await symbolDBAPI.get({
        endPoint: `/themes/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const updateLocalization = async (
    values: ILocalizationFormValues,
    id?: string,
  ) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.Localizations}/${id}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const updateTheme = async (values: IFormValues, id?: string) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.Themes}/${id}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getBrokerProviders = async (): Promise<Array<IBrokerProvider>> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.BrokerProviders,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getBrokerProvider = async (id: string): Promise<IBrokerProvider> => {
    const { data } = await symbolDBAPI.get({
      endPoint: `${Endpoint.BrokerProviders}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const deleteBrokerProvider = async (id: string): Promise<IBrokerProvider> => {
    const { data } = await symbolDBAPI.delete({
      endPoint: `${Endpoint.BrokerProviders}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const createBrokerProvider = async (
    payload: Partial<IBrokerProvider>,
  ): Promise<IBrokerProvider> => {
    const { data } = await symbolDBAPI.post({
      endPoint: Endpoint.BrokerProviders,
      sessionToken: getSessionIdFromCookie() || '',
      data: payload,
    });

    // eslint-disable-next-line no-underscore-dangle
    return getBrokerProvider(data?._id);
  };

  const updateBrokerProvider = async (
    id: string,
    payload: Partial<IBrokerProvider>,
  ): Promise<IBrokerProvider> => {
    await symbolDBAPI.post({
      endPoint: `${Endpoint.BrokerProviders}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
      data: payload,
    });

    return getBrokerProvider(id);
  };

  const getFeedProviders = async (): Promise<Array<IFeedProvider>> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.FeedProviders,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getFeedProviderDetails = async (id: string): Promise<IFeedProvider> => {
    const { data } = await symbolDBAPI.get({
      endPoint: `${Endpoint.FeedProviders}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const createFeedProvider = async (
    payload: IFeedPayload,
  ): Promise<IFeedProvider> => {
    const createResponse: { data: IFeedChangeResponse } =
      await symbolDBAPI.post({
        data: payload,
        endPoint: Endpoint.FeedProviders,
        sessionToken: getSessionIdFromCookie() || '',
      });

    // eslint-disable-next-line no-underscore-dangle
    return getFeedProviderDetails(createResponse.data._id);
  };

  const updateFeedProvider = async (
    id: string,
    payload: IFeedPayload,
  ): Promise<IFeedProvider> => {
    const updateResponse: { data: IFeedChangeResponse } =
      await symbolDBAPI.post({
        data: payload,
        endPoint: `${Endpoint.FeedProviders}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });

    // eslint-disable-next-line no-underscore-dangle
    return getFeedProviderDetails(updateResponse.data._id);
  };

  const deleteFeedProvider = async (
    id: string,
  ): Promise<IFeedChangeResponse> => {
    const deleteResponse: { data: IFeedChangeResponse } =
      await symbolDBAPI.delete({
        endPoint: `${Endpoint.FeedProviders}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });

    return deleteResponse.data;
  };

  const getCurrencyHolidays = async (params: { currencies?: string } = {}) => {
    const result = await symbolDBAPI.get({
      endPoint: Endpoint.CurrencyHolidays,
      sessionToken: getSessionIdFromCookie() || '',
      params,
    });
    return result.data;
  };

  const addCurrencyHoliday = async (payload: CurrencyHoliday) => {
    const result = await symbolDBAPI.post({
      endPoint: Endpoint.CurrencyHolidays,
      sessionToken: getSessionIdFromCookie() || '',
      data: [
        {
          ...payload,
          isHoliday: true,
        },
      ],
    });
    return result.data;
  };

  const removeCurrencyHoliday = async (payload: CurrencyHoliday) => {
    const result = await symbolDBAPI.post({
      endPoint: Endpoint.CurrencyHolidays,
      sessionToken: getSessionIdFromCookie() || '',
      data: [
        {
          ...payload,
          isHoliday: false,
        },
      ],
    });
    return result.data;
  };

  const getReportingProviders = async () => {
    const result = await symbolDBAPI.get({
      endPoint: `${Endpoint.Reporting}`,
      sessionToken: getSessionIdFromCookie() || '',
    });
    return result.data;
  };

  const getSingleReportingProvider = async (id?: string) => {
    try {
      const result = await symbolDBAPI.get({
        endPoint: `${Endpoint.Reporting}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getSingleCurrency = async (id?: string) => {
    try {
      const result = await symbolDBAPI.get({
        endPoint: `${Endpoint.Currencies}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const updateReportingProvider = async (
    values: IReportingFormValues,
    id?: string,
  ) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.Reporting}/${id}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const createReportingProvider = async (values: IReportingFormValues) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.Reporting}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const deleteReportingProvider = async (id?: string) => {
    try {
      const result = await symbolDBAPI.delete({
        endPoint: `${Endpoint.Reporting}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getSymbolTypes = async () => {
    const result = await symbolDBAPI.get({
      endPoint: `${Endpoint.SymbolTypes}`,
      sessionToken: getSessionIdFromCookie() || '',
    });
    return result.data.sort(
      (a: ISymbolTypeFormValues, b: ISymbolTypeFormValues) =>
        a.displayName.localeCompare(b.displayName),
    );
  };

  const updateSymbolType = async (values: ISymbolTypeFormValues) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.SymbolTypes}/${values.name}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getCurrencies = async () => {
    try {
      const { data }: { data: Currency[] } = await symbolDBAPI.get({
        endPoint: Endpoint.Currencies,
        sessionToken: getSessionIdFromCookie() || '',
      });

      return data.sort((a, b) => a._id.localeCompare(b._id));
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const deleteCurrency = async (id?: string) => {
    try {
      const result = await symbolDBAPI.delete({
        endPoint: `${Endpoint.Currencies}/${id}`,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const createCurrency = async (values: Dictionary<unknown>) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.Currencies}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const updateCurrency = async (values: Dictionary<unknown>, id?: string) => {
    try {
      const result = await symbolDBAPI.post({
        endPoint: `${Endpoint.Currencies}/${id}`,
        data: values,
        sessionToken: getSessionIdFromCookie() || '',
      });
      return result.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const getSchedules = async (): Promise<SchedulesResponse> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.Schedules,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getScheduleItem = async (
    id: string,
  ): Promise<SchedulesResponseItem> => {
    const { data } = await symbolDBAPI.get({
      endPoint: `${Endpoint.Schedules}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const updateScheduleItem = async (
    id: string,
    payload: Partial<SchedulesResponseItem>,
  ): Promise<SchedulesResponseItem> => {
    const { data } = await symbolDBAPI.post({
      endPoint: `${Endpoint.Schedules}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
      data: payload,
    });

    return data;
  };

  const createScheduleItem = async (
    payload: Partial<SchedulesResponseItem>,
  ): Promise<SchedulesResponseItem> => {
    const { data } = await symbolDBAPI.post({
      endPoint: `${Endpoint.Schedules}`,
      sessionToken: getSessionIdFromCookie() || '',
      data: payload,
    });

    return data;
  };

  const deleteScheduleItem = async (id: string): Promise<void> => {
    await symbolDBAPI.delete({
      endPoint: `${Endpoint.Schedules}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });
  };

  const getExchanges = async (): Promise<ExchangeItem[]> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.Exchanges,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getExchangeItem = async (id: string): Promise<ExchangeItem> => {
    const { data } = await symbolDBAPI.get({
      endPoint: `${Endpoint.Exchanges}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const deleteExchangeItem = async (id: string): Promise<EmptyResponse> => {
    const { data } = await symbolDBAPI.delete({
      endPoint: `${Endpoint.Exchanges}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const updateExchangeItem = async (
    id: string,
    payload: Partial<ExchangeItem>,
  ) => {
    await symbolDBAPI.post({
      endPoint: `${Endpoint.Exchanges}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
      data: payload,
    });

    return getExchangeItem(id);
  };

  const createExchangeItem = async (payload: Partial<ExchangeModel>) => {
    const { data } = await symbolDBAPI.post({
      endPoint: `${Endpoint.Exchanges}`,
      sessionToken: getSessionIdFromCookie() || '',
      data: payload,
    });

    return getExchangeItem(data?._id);
  };

  const getLegalEntities = async (): Promise<LegalEntitiesResponse> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.LEGAL_ENTITIES,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getExecutionSchemes = async (): Promise<Array<ExecutionSchemaItem>> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.ExecutionSchemes,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getExecutionSchema = async (
    id: string,
  ): Promise<ExecutionSchemaItem> => {
    const { data } = await symbolDBAPI.get({
      endPoint: `${Endpoint.ExecutionSchemes}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const deleteExecutionSchema = async (id: string): Promise<void> => {
    const { data } = await symbolDBAPI.delete({
      endPoint: `${Endpoint.ExecutionSchemes}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const updateExecutionSchema = async (
    id: string,
    payload: Partial<ExecutionSchemaItem>,
  ): Promise<ExecutionSchemaItem> => {
    await symbolDBAPI.post({
      endPoint: `${Endpoint.ExecutionSchemes}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
      data: payload,
    });

    return getExecutionSchema(id);
  };

  const createExecutionSchema = async (
    payload: Partial<ExecutionSchemaItem>,
  ): Promise<ExecutionSchemaItem> => {
    const { data } = await symbolDBAPI.post({
      endPoint: Endpoint.ExecutionSchemes,
      sessionToken: getSessionIdFromCookie() || '',
      data: payload,
    });

    // eslint-disable-next-line no-underscore-dangle
    return getExecutionSchema(data._id);
  };

  const getSections = async (): Promise<Section[]> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.SECTIONS,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  // TODO: define type model
  const getTasks = async (): Promise<Array<unknown>> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.Tasks,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getFeedGateways = async (): Promise<FeedGateway[]> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.FeedGateways,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getBrokerAccounts = async (): Promise<BrokerAccount[]> => {
    const { data } = await symbolDBAPI.get({
      endPoint: Endpoint.BrokerAccounts,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  const getCompiledInstrument = async (id: string) => {
    const { data } = await symbolDBAPI.get({
      endPoint: `${Endpoint.COMPILED_INSTRUMENT}/${id}`,
      sessionToken: getSessionIdFromCookie() || '',
    });

    return data;
  };

  return {
    // localizations
    getSingleLocalization,
    getLocalizations,
    updateLocalization,
    createLocalization,
    deleteLocalization,
    // themes
    getSingleTheme,
    getThemes,
    updateTheme,
    createTheme,
    deleteTheme,
    // currencies
    getCurrencyHolidays,
    addCurrencyHoliday,
    removeCurrencyHoliday,
    // reporting
    getSingleReportingProvider,
    getReportingProviders,
    updateReportingProvider,
    createReportingProvider,
    deleteReportingProvider,
    // symbol types
    getSymbolTypes,
    updateSymbolType,
    // currencies
    getSingleCurrency,
    getCurrencies,
    deleteCurrency,
    updateCurrency,
    createCurrency,
    // feeds
    getFeedProviders,
    getFeedProviderDetails,
    createFeedProvider,
    updateFeedProvider,
    deleteFeedProvider,
    // schedules
    getSchedules,
    getScheduleItem,
    deleteScheduleItem,
    updateScheduleItem,
    createScheduleItem,
    // exchanges
    getExchanges,
    getExchangeItem,
    updateExchangeItem,
    createExchangeItem,
    deleteExchangeItem,
    // brokers
    getBrokerProviders,
    getBrokerProvider,
    deleteBrokerProvider,
    updateBrokerProvider,
    createBrokerProvider,
    // execution schemes
    getExecutionSchemes,
    getExecutionSchema,
    deleteExecutionSchema,
    updateExecutionSchema,
    createExecutionSchema,
    // legal entities
    getLegalEntities,
    // sections
    getSections,
    // tasks
    getTasks,
    // feed gateways
    getFeedGateways,
    // broker accounts
    getBrokerAccounts,
    getCompiledInstrument,
  };
};
