import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CodeEditor } from 'react-ui-kit-exante';

import { stringify } from '~/pages/Instruments/components/InstrumentForm/components/SourceView/useSourceView';
import { symbolDBService } from '~/services/symbolDB.service';

const defaultProps = {
  style: {
    height: 'calc(100vh - 190px)',
  },
  mode: 'javascript',
  readOnly: true,
  wrapEnabled: true,
  theme: 'github',
  disableContentPaddings: true,
};

const CompiledInstrumentView = () => {
  const { id } = useParams();
  const [compiledInstrument, setCompiledInstrument] = useState('');

  const fetchCompiledInstrument = async () => {
    if (id) {
      try {
        const data = await symbolDBService().getCompiledInstrument(id);

        if (data) {
          setCompiledInstrument(stringify(data[0]));
        }
      } catch (error) {
        console.error(error);

        setCompiledInstrument(
          (error as AxiosError).response?.data?.message || 'No data',
        );
      }
    }
  };

  useEffect(() => {
    fetchCompiledInstrument();
  }, [id]);

  return (
    <div>
      <CodeEditor
        {...defaultProps}
        value={compiledInstrument || 'Loading...'}
      />
    </div>
  );
};

export default CompiledInstrumentView;
