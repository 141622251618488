import DownloadingIcon from '@mui/icons-material/Downloading';
import { useCallback, useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { TreeView, Skeleton, useTheme, Loader } from 'react-ui-kit-exante';

import { ReactComponent as FolderIcon } from '~/assets/i/Folder.svg';
import { ReactComponent as FolderOpenIcon } from '~/assets/i/FolderOpen.svg';
import { ReactComponent as SyncIcon } from '~/assets/i/Sync.svg';

import { UnifiedTreeItem } from '../../types';

import {
  TreeContainer,
  TreeItemContainer,
  LoadingContainer,
  LoadingItem,
  FetchMoreButton,
  Label,
  SyncIconContainer,
} from './styled';
import { TreeProps } from './types';
import { getColor, getItemIcon, getChildNodesAbstract } from './utils';

const Tree = ({
  data,
  expanded,
  hideInstrumentIcons,
  loading,
  onSelectNode,
  onFetchMore,
  searchText,
  selected,
  showSyncOnSchedule,
}: TreeProps) => {
  const theme = useTheme();

  const renderTree = useCallback(
    (item: UnifiedTreeItem) => {
      const {
        trading,
        abstract,
        isLoading,
        isPlaceholder,
        nodes,
        syncOnSchedule,
      } = item;

      /**
       * Do not render instruments if property hideInstrumentIcons is true
       */
      if (hideInstrumentIcons && !abstract) {
        return null;
      }

      const color = getColor(theme, trading);
      const EndIcon = getItemIcon(isLoading, abstract);
      const isChildNodeAbstract = getChildNodesAbstract(nodes);

      if (item.isFetchMoreButtonLoading) {
        return (
          <FetchMoreButton type="button" key={item.id}>
            <Loader />
            <span>{item.name}</span>
          </FetchMoreButton>
        );
      }

      if (item.isFetchMoreButton || item.isFetchMoreButton === 0) {
        return (
          <FetchMoreButton
            key={item.id}
            type="button"
            onClick={() =>
              onFetchMore(item.id, item.isFetchMoreButton as number)
            }
          >
            <DownloadingIcon />
            <span>{item.name}</span>
          </FetchMoreButton>
        );
      }

      return (
        <TreeItemContainer
          key={item.id}
          nodeId={item.id}
          label={
            <Label>
              <Highlighter
                searchWords={[searchText]}
                textToHighlight={item.name}
              />
              {showSyncOnSchedule && Boolean(syncOnSchedule) && (
                <SyncIconContainer>
                  <SyncIcon />
                </SyncIconContainer>
              )}
            </Label>
          }
          endIcon={!isPlaceholder && <EndIcon style={{ color }} />}
          expandIcon={<FolderIcon style={{ color }} />}
          collapseIcon={
            hideInstrumentIcons && !isChildNodeAbstract ? (
              <FolderIcon style={{ color }} />
            ) : (
              <FolderOpenIcon style={{ color }} />
            )
          }
          sx={{
            '.MuiTreeItem-label': {
              fontSize: '14px !important',
            },
            '.MuiTreeItem-iconContainer': {
              width: '22px !important',
            },
            '.MuiTreeItem-content': {
              padding: '4px 20px !important',
            },
            '.Mui-selected': {
              backgroundColor: 'transparent !important',
              fontWeight: '700',
            },
            '.Mui-selected .MuiTreeItem-label': {
              fontWeight: '700',
              color: '#252C2E !important',
            },
            '.Mui-expanded': {
              backgroundColor: 'transparent !important',
            },
            '.Mui-expanded .MuiTreeItem-label': {
              color: '#252C2E',
            },
          }}
        >
          {item.nodes.map(renderTree)}
        </TreeItemContainer>
      );
    },
    [searchText, expanded],
  );

  const { current: preloader } = useRef(
    <LoadingContainer>
      {Array.from({ length: 23 }, () => Math.random()).map((key) => (
        <LoadingItem key={key}>
          <Skeleton variant="text" width={25} height={30} />
          <Skeleton variant="text" width={120} height={30} />
        </LoadingItem>
      ))}
    </LoadingContainer>,
  );

  if (loading) {
    return preloader;
  }

  return (
    <TreeContainer>
      {data.map((root) => (
        <TreeView
          selected={selected}
          expanded={expanded}
          key={root.id}
          aria-label="rich object"
          onNodeSelect={onSelectNode}
        >
          {renderTree(root)}
        </TreeView>
      ))}
    </TreeContainer>
  );
};

export default Tree;
