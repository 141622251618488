export enum Headers {
  X_AUTH_SESSIONID = 'x-auth-sessionid',
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
}

export enum Methods {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export const PROD_API = {
  SYMBOLDB_EDITOR: 'symboldb.exante.eu/symboldb-editor',
  STATIC_DATA: 'sds.exante.eu',
  BO: 'node-back.exante.eu',
};

export const TEST_API = {
  SYMBOLDB_EDITOR: 'symboldb-stage.exante.eu/symboldb-editor',
  STATIC_DATA: 'sds-stage.exante.eu',
  BO: 'node-back-stage.exante.eu',
};

export const SDB_APIV1 = '/api/v1.0';
export const API = '/api';
