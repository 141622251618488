import { CodeEditor } from 'react-ui-kit-exante';

import { Container, Column, Header } from './styled';
import useSmartTabs from './useSourceView';

const defaultProps = {
  style: {
    height: 'calc(100vh - 190px)',
  },
  mode: 'javascript',
  readOnly: true,
  theme: 'github',
  disableContentPaddings: true,
};

const SourceView = () => {
  const [value, diff] = useSmartTabs();

  return (
    <Container>
      <Column>
        <Header>Instrument Value</Header>
        <CodeEditor {...defaultProps} value={value} />
      </Column>

      <Column>
        <Header>Difference</Header>
        <CodeEditor {...defaultProps} value={diff} />
      </Column>
    </Container>
  );
};

export default SourceView;
