import {
  forwardRef,
  Ref,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';

import { UnifiedTreeItem } from '~/pages/Instruments/components/Sidebar/types';

import { SidebarRef } from '../../types';

import { Filter, Tree } from './components';
import { SidebarProps } from './components/Tree/types';
import { useFilter, useTree } from './hooks';
import { Container } from './styled';

const Sidebar = (
  { hideInstrumentIcons, showSyncOnSchedule }: SidebarProps,
  ref: Ref<SidebarRef>,
) => {
  const [prepareData, setPrepareData] = useState<UnifiedTreeItem[]>([]);
  const filter = useFilter();

  const {
    data,
    expanded,
    handleForceReset,
    handleRefresh,
    handleSelectNode,
    handleFetchMore,
    loading,
    selected,
  } = useTree(filter.state);
  const getPrepareData = (itemMain: UnifiedTreeItem): UnifiedTreeItem => {
    if (itemMain?.nodes?.length === 0) {
      return itemMain;
    }

    return {
      ...itemMain,
      nodes: itemMain?.nodes.map((item) => {
        if (
          typeof item?.trading === 'number' ||
          typeof item?.trading === 'boolean'
        ) {
          return getPrepareData(item);
        }

        return getPrepareData({ ...item, trading: itemMain?.trading });
      }),
    };
  };

  useImperativeHandle(ref, () => {
    return {
      refresh: (id?: string) => {
        filter.onReset();
        handleForceReset(id);
      },
    };
  });

  useEffect(() => {
    setPrepareData(data.map(getPrepareData));
  }, [data]);

  return (
    <Container>
      <Filter {...filter} onRefresh={handleRefresh} />
      <Tree
        data={prepareData}
        expanded={expanded}
        hideInstrumentIcons={hideInstrumentIcons}
        loading={loading}
        onSelectNode={handleSelectNode}
        onFetchMore={handleFetchMore}
        searchText={filter.state.search}
        selected={selected}
        showSyncOnSchedule={showSyncOnSchedule}
      />
    </Container>
  );
};

export default forwardRef(Sidebar);
